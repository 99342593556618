import { Container, Box } from "@mui/material"
import Image from "next/image"
import { Trans } from "next-i18next"

import Button from "components/Buttons/Button"
import Link from "components/i18n/Link"

import useTranslation from "hooks/useTranslation"

import { getServerTranslations } from "utils/i18n/getServerTranslations"
import { makeStyles } from "makeStyles"
import { GetStaticProps } from "next"

const useStyles = makeStyles()({
	root: {
		textAlign: "center",
		color: "#f7fafa",
	},
	error: {
		fontSize: "50px",
		marginBottom: "-30px",
	},
})

const NotFoundPage = () => {
	const { classes } = useStyles()
	const { t } = useTranslation("common")

	return (
		<Box
			py={5}
			style={{
				background:
					"url(https://res.cloudinary.com/safehero/image/upload/v1571664483/background_n4xzwh.png)",
				minHeight: "500px",
				paddingBottom: "100px",
			}}
			id="notFoundPage"
		>
			<Container className={classes.root}>
				<h1 className={classes.error}>404</h1>
				<p>
					<Trans t={t} i18nKey="pageCouldNotBeFound" />
				</p>

				<div style={{ maxWidth: "400px", margin: "auto", marginTop: "30px" }}>
					<Image
						alt="hero"
						src="https://res.cloudinary.com/safehero/image/upload/v1571665773/undraw_super_woman_dv0y_1_w3q74n.svg"
						width={400}
						height={400}
						style={{
							maxWidth: "100%",
							maxHeight: "400px",
							height: "auto",
							objectFit: "contain",
						}}
					/>
				</div>
				<Link href="/">
					<Box pt={5} style={{ maxWidth: "220px", margin: "auto" }}>
						<Button color="secondary">{t("goToHomepage")}</Button>
					</Box>
				</Link>
			</Container>
		</Box>
	)
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	const props = {
		...(await getServerTranslations(locale, ["common"])),
	}

	return {
		props,
	}
}

export default NotFoundPage
